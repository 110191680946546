import React from 'react'
import Data from './table'
import Descriptions from './descriptions'
import Benchmark from './benchmarks'
import { Table } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

export default function MortgageRates() {
  return (
    <>
      <Table>
        <Data />
        <Benchmark />
      </Table>
      <Descriptions />
    </>
  );
}
