import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

class RatesTable extends React.Component {

    render () {    

    return (
      <>
          <Thead>
            <Tr>
              <Th>Term</Th>
              <Th>Current Rate (%)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.ratesRow.map((row) => {
              return <Tr key={row.id}>
              {row.formfield_rate ?
                <Td>
                  <p>{row.formfield_term}</p>
                </Td> 
                :
                <Td colspan="2">
                  <p>{row.formfield_term}</p>
                </Td>
               }
              {row.formfield_rate ? 
                <Td>
                  <p>{row.formfield_rate}</p>
                </Td> 
                : ''
              }
            </Tr>
            })}
          </Tbody>
      </>
    ) 
  }
}

export default () => (
  <StaticQuery
        query={graphql`
            query RatesTable {
            strapiRateForms {
              rateform_row {
                id
                formfield_term
                formfield_rate
              }
            }
          }
        `}
    render={(data) => (
      <RatesTable ratesRow={data.strapiRateForms.rateform_row} />
    )}
  />
)