import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

class RatesTableData extends React.Component {

    render () {    

    return (
      <div className="table-descriptions">
        {this.props.ratesData.map((data) => {
            return <div key={data.id}>
                <ReactMarkdown>{data.formrow_description}</ReactMarkdown>
            </div>      
        })}
      </div>
    )
  }
}

export default () => (
  <StaticQuery
        query={graphql`
        query {
            strapiRateForms {
              rateform_description {
                id
                formrow_description
              }
            }
          }                  
        `}
    render={(data) => (
      <RatesTableData ratesData={data.strapiRateForms.rateform_description} />
    )}
  />
)