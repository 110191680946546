import React from "react"
import { graphql } from "gatsby"
import { Container, Grid, Paper, Hidden } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import BlueCenter from "../components/website/BlueCenter"
import Table from "../components/table/index"


export default function Rates({data}) {
  return (
  <Layout>
    <SEO title="Rates" />
    {data.allStrapiRates.edges.map(({ node }) => (
    <>
      <Intro key={node.rates_intro.id}
        title={node.rates_intro.intro_title}
        text={node.rates_intro.intro_description}
        img={node.rates_intro.Intro_hero.publicURL}
      />
      <div id="table" />
      <BlueCenter id={node.rates_bluesection.id}
        title={node.rates_bluesection.Bluesection_title}
        description={node.rates_bluesection.bluesection_title_description}
        intro={true}
      />
      <div className="blue-white-bg">
        <Container className="pb-96">
          <Grid container className="flex justify-center">
            <Grid item md={6} className="mt-16">
              <Paper elevation={5}>
                <Table />
              </Paper>            
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="mb-40" />
      <Hidden smDown>
        <div className="calc-offset" />
      </Hidden>
    </>
  ))}
  </Layout>
  )
}

export const query = graphql`
query Rates {
  allStrapiRates {
    edges {
      node {
        rates_intro {
          id
          intro_title
          intro_description
          Intro_hero {
            publicURL
          }
        }
        rates_bluesection {
          id
          Bluesection_title
          bluesection_title_description
        }
      }
    }
  }
}
`