import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Tr, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

class Benchmarks extends React.Component {
  render() {
    return (
      <>
        {this.props.benchMark.map(row => {
          return (
            <Tr key={row.id} className="benchmark">
              {row.formfield_rate ? (
                <Td>
                  <p>{row.formfield_term}</p>
                </Td>
              ) : (
                <Td colspan="2">
                  <p>{row.formfield_term}</p>
                </Td>
              )}
              {row.formfield_rate ? (
                <Td>
                  <p>{row.formfield_rate}</p>
                </Td>
              ) : (
                ""
              )}
            </Tr>
          )
        })}
      </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query Benchmark {
        strapiRateForms {
          rateform_benchmarks {
            id
            formfield_term
            formfield_rate
          }
        }
      }
    `}
    render={data => (
      <Benchmarks benchMark={data.strapiRateForms.rateform_benchmarks} />
    )}
  />
)
