import React from 'react'
import { Container, Grid } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

export default function BlueCenter(props) {

    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '20%'
      })  

    return (
        <div className="blue-section pt-64">
            <Container>
                <Grid container className="flex justify-center">
                        <Grid item md={6} sm={12} className="text-center white-text">
                            <motion.div className="fade-in" ref={ref} animate={{ opacity: inView ? 1 : 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
                                <h3>{props.title}</h3>
                                <ReactMarkdown>{props.description}</ReactMarkdown>
                            </motion.div>
                        </Grid>
                </Grid>
                <Grid container className="flex justify-center">
                    <Grid item md={6} sm={12}>
                        {props.component}
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}